<script>
import { getValue, mapArray } from '@emobg/web-utils';
import find from 'lodash/find';

import { computed, defineComponent, ref } from 'vue';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

import { userData } from '@/stores/User/UserData/UserDataMapper';
import { getLocationsCollection } from '@/stores/Locations/LocationsMapper';

import { GTM_SEARCH_EVENT_VALUES } from '@/constants/gtm';

import cfg from '@/config';
import { useOperatorLocationsList } from '../../composables/useOperatorLocationsList';
import { useFavouriteLocationsList } from '../../composables/useFavouriteLocationsList';

import { toLocationPrediction, toPredictionPlace } from './utils';

export default defineComponent({
  props: {
    currentPosition: Boolean,
  },
  setup(_, { emit }) {
    // Favourite locations
    const {
      validFavouriteLocationsList,
      toFavouriteLocationPrediction,
    } = useFavouriteLocationsList();

    // Operator locations
    const {
      operatorLocationsList,
      toOperatorLocationPrediction,
    } = useOperatorLocationsList();

    const { googleMapsKey } = cfg.data;

    const userDataRef = computed(userData);
    const locationsCollectionRef = computed(getLocationsCollection);

    const selectedAddress = ref(null);
    const googleInstance = googleMapsLoader.getInstance();

    const hasDedicatedFleet = !!getValue(userDataRef.value, 'company.dedicated_fleet_cs_operator', false);

    const customPredictions = computed(() => [
      // Concat favourite locations
      ...mapArray(validFavouriteLocationsList.value, toFavouriteLocationPrediction),
      // Concat operator locations
      ...(hasDedicatedFleet ? mapArray(operatorLocationsList.value, toOperatorLocationPrediction) : []),
      // Concat locations
      ...mapArray(locationsCollectionRef.value, toLocationPrediction),
    ]);

    const onChangeSelectedAddress = place => {
      selectedAddress.value = place;
      const customPrediction = find(customPredictions.value, ['description', place.address]);
      const searchType = customPrediction ? customPrediction.search_type : GTM_SEARCH_EVENT_VALUES.address;
      emit('on:select-map-place', { place: toPredictionPlace(place), searchType });
    };

    return {
      googleMapsKey,
      customPredictions,
      googleInstance,
      onChangeSelectedAddress,
      selectedAddress,
      operatorLocationsList,
      validFavouriteLocationsList,
    };
  },
});
</script>

<template>
  <ui-location-input
    key="address-location_input"
    :gkey="googleMapsKey"
    :placeholder="$t('map.input_search_placeholder')"
    :value="selectedAddress && selectedAddress.address"
    :icon="currentPosition ? ICONS.locationOn : ICONS.locationOff"
    :custom-predictions.prop="customPredictions"
    consent
    class="w-100"
    data-test-id="search_address"
    @clickLocationIcon="() => $emit('on:center-user-position')"
    @changeplace="({ detail }) => onChangeSelectedAddress(detail)"
  />
</template>
