<template>
  <div class="AvailabilityFilters py-3 px-4 emobg-border-top-1 emobg-border-color-ink-lightest w-100">
    <div
      v-show="availabilityLoadingStatus"
      class="AvailabilityFilters__loader"
    />
    <div class="d-flex align-items-center">
      <div class="emobg-font-default">
        {{ $t('views.booking.filters.label_filter_by') }}
      </div>
      <div
        :class="[
          'AvailabilityFilters__filter-list d-flex justify-content-between pl-4',
          {
            'AvailabilityFilters__filter-list--disabled': availabilityLoadingStatus,
          }
        ]"
      >
        <div
          v-for="(filter, index) in actionableFilters"
          :key="index"
          class="AvailabilityFiltersButton"
        >
          <template v-if="isCollapsed(index)">
            <ui-button
              :color="areFiltersApplied(filter) ? COLORS.primary :GRAYSCALE.inkLighter"
              :face="areFiltersApplied(filter) ? FACES.fill : FACES.outline"
              :size="SIZES.medium"
              :data-test-id="`availability_filter-${filter.filterName}-display_options`"
              narrow
              class="mr-2"
              @clickbutton="openAvailabilityFilter({ filter })"
            >
              <span :class="[{ 'emobg-color-ink' : !areFiltersApplied(filter)}]">
                {{ $t(`views.booking.filters.${filter.filterName}`) }}
              </span>
            </ui-button>
            <ui-card
              v-if="filter.opened"
              :class="[
                'AvailabilityFiltersPanel',
                {
                  'AvailabilityFiltersPanel--right': isLastOne(filter)
                }
              ]"
            >
              <div class="mb-4">
                <template v-for="(option, optionIndex) in filter.options">
                  <ui-checkbox
                    :key="`${filter.filterName}${optionIndex}`"
                    :size="SIZES.small"
                    :checked="includes(filter.selected, option.internalName)"
                    :name="filter.filterName"
                    :caption="option.name"
                    :value="option.internalName"
                    class="d-block"
                    @changevalue="({ detail }) =>
                      includes(filter.selected, detail)
                        ? filter.selected = without(filter.selected, detail)
                        : filter.selected.push(detail)
                    "
                  />
                </template>
              </div>
              <div
                class="d-flex justify-content-between align-items-center"
                :data-test-id="`availability_filter-action_${kebabCase(filter.filterName)}`"
              >
                <ui-button
                  :color="GRAYSCALE.inkLight"
                  :face="FACES.text"
                  :size="SIZES.small"
                  class="AvailabilityFiltersPanel__button ml-n3"
                  data-test-id="reset-button"
                  @clickbutton="onClickResetFilter({ filter })"
                >
                  {{ $t('views.booking.filters.btn_reset') }}
                </ui-button>
                <ui-button
                  :face="FACES.text"
                  data-test-id="apply-button"
                  class="AvailabilityFiltersPanel__button mr-n3"
                  @clickbutton="onClickApplyFilter({ filter })"
                >
                  {{ $t('views.booking.filters.btn_apply') }}
                </ui-button>
              </div>
            </ui-card>
          </template>
        </div>
        <div
          v-if="size(collapsedFilters)"
          class="AvailabilityFiltersButton AvailabilityFiltersButton--static"
        >
          <ui-button
            :color="COLORS.primary"
            :face="size(collapsedFiltersApplied) ? FACES.fill : FACES.outline"
            :size="SIZES.small"
            data-test-id="availability_filter-more_filters"
            @clickbutton="onClickOpenPanelMoreFilters"
          >
            {{ $t('views.booking.filters.btn_more_filters') }}
          </ui-button>
        </div>
      </div>
      <ui-button
        v-if="isSomeFiltersApplied"
        :face="FACES.text"
        :size="SIZES.small"
        compact
        class="AvailabilityFilters__reset-button ml-auto"
        @clickbutton="onClickResetAllFilters()"
      >
        {{ $t('views.booking.filters.btn_reset_all') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import kebabCase from 'lodash/kebabCase';
import last from 'lodash/last';
import size from 'lodash/size';
import throttle from 'lodash/throttle';
import includes from 'lodash/includes';
import without from 'lodash/without';

// Composable
import { useAvailability } from '@/composable/Availability/useAvailability';
import { useAvailabilityFilter } from '@/composable/Availability/useAvailabilityFilters';

import { useTrackingGTM } from '@/composable/GTM/gtm';

// Stores
import { nameSpace as profileNameSpace } from '@Profile/store';
import { nameSpace as CSOperatorNameSpace } from '@/vue/stores/CSOperator/CSOperatorStore';
import * as BookingStore from '@/vue/stores/Booking/BookingStore';
import { DELIMITERS, getValue } from '@emobg/web-utils';

// Constants
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { GTM_EVENTS, GTM_FILTERS_EVENTS_KEYS } from '@/constants/gtm';

const FILTERS_NOT_COLLAPSED = 2;

export default {
  name: 'AvailabilityFilters',

  setup() {
    const {
      collapsedFilters,
      notCollapsedFilters,
      applyFilters,
      areFiltersCollapsed,
      areFiltersApplied,
      setNumFiltersAreCollapsed,
      isSomeFiltersApplied,
      collapsedFiltersApplied,
      setFilterAreCollapsed,
      setAvailabilityFilters,
      openAvailabilityFilter,
      isCollapsedFiltersOpen,
      resetAllSelectedFilters,
      resetSelectedFilters,
      openMoreFiltersPanel,
    } = useAvailabilityFilter();
    const { availabilityLoadingStatus, setAvailabilityResponse } = useAvailability();
    const { notifyError } = useNotifications();
    const { trackInteractionEvent } = useTrackingGTM();
    return {
      availabilityLoadingStatus,
      isSomeFiltersApplied,
      collapsedFilters,
      notCollapsedFilters,
      collapsedFiltersApplied,
      setAvailabilityFilters,
      setFilterAreCollapsed,
      openAvailabilityFilter,
      isCollapsedFiltersOpen,
      applyFilters,
      areFiltersCollapsed,
      setAvailabilityResponse,
      resetAllSelectedFilters,
      resetSelectedFilters,
      openMoreFiltersPanel,
      areFiltersApplied,
      setNumFiltersAreCollapsed,
      notifyError,
      trackInteractionEvent,
    };
  },

  data() {
    return {
      collapse: false,
    };
  },
  computed: {
    ...mapState(BookingStore.nameSpace, {
      estimatedTripMileage: state => state.estimatedTripMileage,
    }),
    ...mapGetters(profileNameSpace, {
      currentProfile: 'getCurrentProfile',
    }),
    ...mapGetters(CSOperatorNameSpace, {
      visitedCsOperator: 'getVisitedCsOperator',
    }),

    isLastOne() {
      return filter => {
        const lastItem = last(this.notCollapsedFilters);
        return !this.areFiltersCollapsed && lastItem && lastItem.filterName === filter.filterName;
      };
    },

    actionableFilters() {
      return this.notCollapsedFilters.filter(availabilityFilter => size(availabilityFilter.options) > 1);
    },

  },

  watch: {
    visitedCsOperator(val, old) {
      if (val !== old && val) {
        this.setAvailabilityFilters({
          cs_operator_uuid: val.uuid,
          user_profile_uuid: this.currentProfile.getUUID(),
          trip_mileage: this.estimatedTripMileage,
        });
      }
    },
    estimatedTripMileage(newTripMileage) {
      if (newTripMileage) {
        this.setAvailabilityFilters({
          cs_operator_uuid: this.visitedCsOperator.getUUID(),
          user_profile_uuid: this.currentProfile.getUUID(),
          trip_mileage: newTripMileage,
        });
      }
    },
  },

  async created() {
    this.setNumFiltersAreCollapsed(FILTERS_NOT_COLLAPSED);

    if (this.visitedCsOperator) {
      await this.setAvailabilityFilters({
        cs_operator_uuid: this.visitedCsOperator.getUUID(),
        user_profile_uuid: this.currentProfile.getUUID(),
        trip_mileage: this.estimatedTripMileage,
      });
    }
  },

  mounted() {
    this.resize({
      target: {
        innerWidth: window.innerWidth,
      },
    });

    window.addEventListener('resize', throttle(this.resize, 200));
  },

  detroyed() {
    window.removeEventListener('resize', throttle(this.resize, 200));
  },

  methods: {
    kebabCase,
    size,
    includes,
    without,

    onClickResetFilter({ filter }) {
      this.resetSelectedFilters(filter);

      this.trackInteractionEvent({
        eventName: GTM_EVENTS.removeFilter,
        eventData: {
          [GTM_FILTERS_EVENTS_KEYS.filterType]: getValue(filter, 'filterName', undefined),
        },
      });

      this.updateAvailabity();
    },

    async onClickApplyFilter({ filter }) {
      await this.applyFilters(filter);

      this.trackInteractionEvent({
        eventName: GTM_EVENTS.applyFilter,
        eventData: {
          [GTM_FILTERS_EVENTS_KEYS.filterType]: getValue(filter, 'filterName', undefined),
          [GTM_FILTERS_EVENTS_KEYS.filterValue]: filter.applied.join(DELIMITERS.pipe),
        },
      });

      this.updateAvailabity();
    },

    onClickResetAllFilters() {
      this.resetAllSelectedFilters();

      this.trackInteractionEvent({ eventName: GTM_EVENTS.removeFilter });

      this.updateAvailabity();
    },

    updateAvailabity() {
      this.setAvailabilityResponse(null);

      try {
        this.$emit('apply-filters');
      } catch (error) {
        this.notifyError({
          text: getValue(error, 'message', undefined),
          textAction: this.$t('buttons.ok'),
        });
      }
    },

    resize(evt) {
      const screenSize = evt.target.innerWidth;
      const collapsed = screenSize < 1200;
      if (this.areFiltersCollapsed !== collapsed) {
        this.setFilterAreCollapsed(collapsed);
      }
    },

    isCollapsed(i = -1) {
      const isResolutionBiggerThant1200 = !this.collapse && i !== -1;
      const isSmallerAndShouldBeShowed = this.collapse && (i < FILTERS_NOT_COLLAPSED || i === -1);

      return isResolutionBiggerThant1200 || isSmallerAndShouldBeShowed;
    },

    onClickOpenPanelMoreFilters() {
      this.openMoreFiltersPanel(!this.isCollapsedFiltersOpen);
    },
  },

};

</script>
